import React from 'react';
import './categorycard.scss';

function CategoryCard({imageid}){
return(
  <div className="category__card" id={imageid}>


  </div>
);
}

export default CategoryCard;
