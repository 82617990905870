import React from "react";
import "./featuredcard.scss";

function FeaturedCard(){
return(<div className="featured__card-holder">
<div className="featured__card">


</div>
</div>);
}


export default FeaturedCard;
