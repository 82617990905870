const iconsphone = [
  {class:"explore-svg",name:"Projects",value:"1"},
{class:"stat-svg",name:"Case Studies",value:"2"},
{class:"wallet-svg",name:"Blog Posts",value:"3"},
{class:"profile-svg",name:"Profile",value:"4"}
];

const iconsdesk = [
  {class:"explore-svg",name:"Projects",value:"1"},
{class:"stat-svg",name:"Case Studies",value:"2"},
{class:"wallet-svg",name:"Blog Posts",value:"3"},
{class:"profile-svg",name:"Profile",value:"4"}
];

export {iconsphone,iconsdesk};
